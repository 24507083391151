import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

import { IsfService } from 'src/app/services/isf.service';
import { CoordinationRoleOptions, ExtraRoleOptions, getDateDescription, getDateStringForBackend, parseDateUTC, ParticipantRoleOptions } from 'src/app/utils/utils';
import { environment } from '../../../environments/environment';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { AbstractControl } from '@angular/forms';
import { SnackbarService } from 'src/app/services/error-snackbar.service';


@Component({
  selector: 'participant_dialog',
  template: `
    <h1 mat-dialog-title>{{data.contact.name}} {{data.contact.lastname}} ({{data.contact.pronoun}})</h1>
    <div mat-dialog-content class="dialog-content">

      <mat-accordion>
        <mat-expansion-panel class="custom-expansion-panel-color"  [expanded]="expandedContactData" (opened)="expandedContactData = true" (closed)="expandedContactData = false">
          <mat-expansion-panel-header>
            <mat-panel-title>Datos de contacto (no editable)</mat-panel-title>
          </mat-expansion-panel-header>
          <mat-card>
            <mat-form-field>
              <mat-label>DNI</mat-label>
              <input matInput [(ngModel)]="data.contact.dni" [disabled]=true>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Fecha de Nacimiento</mat-label>
              <input matInput [value]="this.showDate()" [disabled]=true>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Teléfono</mat-label>
              <input matInput [(ngModel)]="data.contact.phone" [disabled]=true>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Email</mat-label>
              <input matInput [(ngModel)]="data.contact.email" [disabled]=true>
            </mat-form-field>
          </mat-card>
        </mat-expansion-panel>
        
      </mat-accordion>
      <mat-tab-group (selectedTabChange)="onTabChange($event)">
        <mat-tab label="Cambiar Estado / Rol">
          <mat-card>
            <div class="alert alert-warning">
              Desde aquí, al cambiar el rol, se cerrará la participación actual y se creará una nueva.
            </div>
            <mat-form-field>
              <mat-label>Rol</mat-label>
              <mat-select [disabled]="!isEditMode" [(ngModel)]="data.participation.role">
                <mat-option *ngFor="let option of participantRoleOptions" [value]="option">{{ option }}</mat-option>
                <mat-divider></mat-divider>
                <mat-option *ngFor="let option of coordinationRoleOptions" [value]="option">{{ option }}</mat-option>
                <mat-divider></mat-divider>
                <mat-option *ngFor="let option of extraRoleOptions" [value]="option">{{ option }}</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Status</mat-label>
              <mat-select [disabled]="!isEditMode" [(ngModel)]="data.participation.status">
                <mat-option *ngFor="let option of statusOptions" [value]="option">{{ option }}</mat-option>
              </mat-select>
            </mat-form-field>
          </mat-card>
          <div mat-dialog-actions>
            <button mat-button (click)="onClose()" cdkFocusInitial>Cerrar</button>
            <button mat-button class="confirm-button" (click)="onEdit()">{{isEditMode ? 'Guardar' : 'Editar'}}</button>
          </div>
        </mat-tab>




        <mat-tab label="Corregir participación (Salesforce)">
          <mat-card>
            <div class="alert alert-warning">
              Desde aquí, cualquier cambio impactará en la participación actual. Usar para correcciones de malas inicializaciones (por rol o estado), o cierres a destiempo. Las fechas asistencia límite deben estar dentro del rango de la participación.
            </div>
            <mat-form-field>
              <input disabled matInput [matDatepicker]="dateInitMax" [(ngModel)]="data.participation.date_init_max" placeholder="Fecha inicio máxima (primer asistencia)">
              <mat-datepicker-toggle matSuffix [for]="dateInitMax"></mat-datepicker-toggle>
              <mat-datepicker touchUi #dateInitMax></mat-datepicker>
            </mat-form-field>
            <mat-form-field>
              <input matInput [disabled]="!isFixMode" [matDatepicker]="dateInit" [(ngModel)]="data.participation.date_init" placeholder="Fecha inicio participación">
              <mat-datepicker-toggle matSuffix [for]="dateInit"></mat-datepicker-toggle>
              <mat-datepicker touchUi #dateInit></mat-datepicker>
            </mat-form-field>
            <mat-form-field *ngIf="isCompleted">
              <input disabled matInput [matDatepicker]="dateEndMin" [(ngModel)]="data.participation.date_end_min" placeholder="Fecha cierre mínima (última asistencia)">
              <mat-datepicker-toggle matSuffix [for]="dateEndMin"></mat-datepicker-toggle>
              <mat-datepicker touchUi #dateEndMin></mat-datepicker>
            </mat-form-field>
            <mat-form-field *ngIf="isCompleted">
              <input matInput [disabled]="!isFixMode" [matDatepicker]="dateEnd" [(ngModel)]="data.participation.date_end" placeholder="Fecha cierre participación">
              <mat-datepicker-toggle matSuffix [for]="dateEnd"></mat-datepicker-toggle>
              <mat-datepicker touchUi #dateEnd></mat-datepicker>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Rol</mat-label>
              <mat-select [disabled]="!isFixMode" [(ngModel)]="data.participation.role">
                <mat-option *ngFor="let option of participantRoleOptions" [value]="option">{{ option }}</mat-option>
                <mat-divider></mat-divider>
                <mat-option *ngFor="let option of coordinationRoleOptions" [value]="option">{{ option }}</mat-option>
                <mat-divider></mat-divider>
                <mat-option *ngFor="let option of extraRoleOptions" [value]="option">{{ option }}</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Status</mat-label>
              <mat-select [disabled]="!isFixMode" [(ngModel)]="data.participation.status" (selectionChange)="onStatusChange()">
                <mat-option *ngFor="let option of statusOptions" [value]="option">{{ option }}</mat-option>
              </mat-select>
            </mat-form-field>
            <div class="alert alert-error" *ngIf="errorMessage">{{ errorMessage }}</div>
          </mat-card>
          <div mat-dialog-actions>
            <button mat-button (click)="onClose()" cdkFocusInitial>Cerrar</button>
            <button mat-button class="confirm-button" (click)="onFix()">{{isFixMode ? 'Guardar' : 'Editar'}}</button>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  `,
  styleUrls: ['./participant-dialog.component.scss']
})

export class ParticipantDialogComponent {
  constructor(
    private _isfService: IsfService,
    public dialogRef: MatDialogRef<ParticipantDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ParticipantDialogData,
  ) {
    this.isEditMode = false
    this.isFixMode = false
    this.isCompleted = false
    this.expandedContactData = false
    this.errorMessage = null;
  }

  isEditMode: boolean
  isFixMode: boolean
  isCompleted: boolean
  expandedContactData: boolean
  statusOptions = ['active', 'completed']
  errorMessage: string | null = null

  participantRoleOptions = ParticipantRoleOptions;
  coordinationRoleOptions = CoordinationRoleOptions;
  extraRoleOptions = ExtraRoleOptions;

  showDate() {
    return getDateDescription(this.data.contact.birthdate, false)
  }

  inputsAreValid(): boolean {
    this.errorMessage = null;
    if (this.data.participation.date_init_max 
      && this.data.participation.date_init 
      && this.data.participation.date_init_max < this.data.participation.date_init) {
        this.errorMessage = 'La fecha de inicio no puede ser posterior a la fecha de primer asistencia';
      }
    if (this.data.participation.date_end_min
      && this.data.participation.date_end
      && this.data.participation.date_end_min > this.data.participation.date_end) {
        this.errorMessage = 'La fecha de cierre no puede ser anterior a la fecha de la última asistencia';
      }
    if (this.data.participation.date_init
      && this.data.participation.date_end
      && this.data.participation.date_init > this.data.participation.date_end) {
        this.errorMessage = 'La fecha de inicio no puede ser posterior a la fecha de cierre';
      }
    if (this.data.participation.status == 'completed' && !this.data.participation.date_end) {
        this.errorMessage = 'La fecha de cierre es requerida si el estado es completado';
      }
    return !this.errorMessage;
  }

  calculateIsCompleted(){
    this.isCompleted = this.data.participation.status == 'completed';
  }

  onEdit(): void {
    if (this.isEditMode) {
      const today = new Date;
      const id = this.data.participation.id;
      const newValues = this.data.participation as any;
      const originalValues = this.data.originalParticipation as any;

      // Solo actualizar uno de los 2 campos. Confirmar cuál cambió
      const updatedParticipation = Object.keys(this.data.participation).reduce((output, key) => {
        if (originalValues[key] != newValues[key]) {
          output[key] = newValues[key]
        }
        return output;
      }, {} as Record<string, any>);

      updatedParticipation.change_date = getDateStringForBackend(today)

      this._isfService.updateParticipant(id, updatedParticipation).then(
        (response) => {
          if (response) {
            SnackbarService.showSuccess('Participación actualizada!');
            this.dialogRef.close({updatedParticipant: response});
          }
        }
      ).catch((error: any) => {
        console.error(error);
      });

    }
    this.isEditMode = !this.isEditMode
    this.expandedContactData = false
  }

  onFix(): void {
    if (this.isFixMode) {

      // Validar fechas
      if (!this.inputsAreValid()){
        return;
      }

      const id = this.data.participation.id;
      const newValues = this.data.participation as any;

      // Solo actualizar uno de los 2 campos. Confirmar cuál cambió
      const updatedParticipation = Object.keys(this.data.participation).reduce((output, key) => {
          output[key] = newValues[key]
        return output;
      }, {} as Record<string, any>);

      updatedParticipation.date_init = getDateStringForBackend(updatedParticipation.date_init)
      if (updatedParticipation.date_end)
        updatedParticipation.date_end = getDateStringForBackend(updatedParticipation.date_end)

      this._isfService.updateParticipantFull(id, updatedParticipation).then(
        (response) => {
          if (response) {
            SnackbarService.showSuccess('Participación corregida!');
            this.dialogRef.close({updatedParticipant: response});
          }
        }
      ).catch((error: any) => {
        console.error(error);
      });
    }
    this.isFixMode = !this.isFixMode
    this.expandedContactData = false
  }

  onClose(): void {
    this.dialogRef.close(null);
  }

  onStatusChange(): void {
    this.calculateIsCompleted();
    if (this.data.participation.status == 'active'){
      this.data.participation.date_end = this.data.originalParticipation.date_end ? 
        this.data.originalParticipation.date_end :
        undefined;
    }
  }

  async onTabChange(event: MatTabChangeEvent) {
    if (event.index == 1){
      this.calculateIsCompleted();
      // Cargar datos
      try {
        const promises = [
          this._isfService.getParticipantFull(this.data.participation.id)
        ];
        const [participantResponse] = await Promise.all(promises);
        if (participantResponse['date_init']){
          this.data.participation.date_init = parseDateUTC(participantResponse['date_init'])
          this.data.originalParticipation.date_init = this.data.participation.date_init
        }
        if (participantResponse['date_end']){
          this.data.participation.date_end = parseDateUTC(participantResponse['date_end'])
          this.data.originalParticipation.date_end = this.data.participation.date_end
        }
        if (participantResponse['date_init_max']){
          this.data.participation.date_init_max = parseDateUTC(participantResponse['date_init_max'])
        }
        if (participantResponse['date_end_min']){
          this.data.participation.date_end_min = parseDateUTC(participantResponse['date_end_min'])
        }
      } catch(error: any) {
        console.error(error);
      }
    }
  }
}

export interface ParticipantDialogData {
  contact: {
    name: string;
    lastname: string;
    pronoun: string;
    dni: string;
    email: string;
    phone: string;
    birthdate: string;
  };
  originalParticipation:{
    role: string;
    status: string;
    date_init?: Date; // fecha de inicio del program engagement
    date_end?: Date; // fecha de inicio del program engagement
  },
  participation: {
    id: string;
    role: string;
    status: string;
    date_init?: Date; // fecha de inicio del program engagement
    date_end?: Date; // fecha de fin del program engagement
    date_init_max?: Date; // fecha del primer service delivery
    date_end_min?: Date; // fecha del ultimo service delivery
  }
}
